export const environment = {
  production: true,
  apiServer: '//promo-api-qa.stylifashion.com',
  apiNjServer: '//promo-apinj-qa.stylifashion.com',
  remoteConfig: '//dev-configpanel.web.app/home',
  authServiceUrl: 'https://dev-sentinel-api.stylifashion.com/api/v1/auth',
  firebaseConfig: {
    apiKey: 'AIzaSyCM8p8y22CgYwMdjiKLFfl3lWQ3cf_N1H8',
    authDomain: 'supply-qa.firebaseapp.com',
    projectId: 'supply-qa',
    storageBucket: 'supply-qa.appspot.com',
    messagingSenderId: '428145837378',
    appId: '1:428145837378:web:8d627c1712a700f6c2f4db',
  },
};
