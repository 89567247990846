import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CouponComponent } from './coupon/coupon.component';
import { RedeemComponent } from './redeem/redeem.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CouponFormComponent } from './coupon/coupon-form/coupon-form.component';
import { LoginComponent } from './admin/login/login.component';
import { VerifyOtpComponent } from './admin/verify-otp/verify-otp.component';
import { AuthGuard } from "./auth/guard/auth.guard";
import { SettingComponent } from "./setting/setting.component"
import { ActivitylogComponent } from './activitylog/activitylog.component';
import { ImportCouponComponent } from './import-coupon/import-coupon.component';
import { ConfigComponent } from "./setting/config/config.component"
import { UpdateCouponTypeComponent } from './update-coupon-type/update-coupon-type.component';
import { BulkCouponComponent } from './bulk-coupon/bulk-coupon.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { ApproveFormComponent } from './approvals/approve-form/approve-form.component';
import { UsersComponent } from './users/users.component';
import { ActivitylogUserComponent } from './activitylog/activitylog-user/activitylog-user.component';
import { InfluencerComponent } from './influencer/influencer.component';
import { InfluencerSectionComponent } from './coupon/coupon-form/influencer-section/influencer-section.component';
import { AdminConfigComponent } from './admin-config/admin-config.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'verify-otp', component: VerifyOtpComponent },
  { path: 'coupon', component: CouponComponent, canActivate: [AuthGuard] },
  { path: 'coupon/edit/:coupon_id', component: CouponFormComponent, data: { coupon_id: "" }, canActivate: [AuthGuard] },
  { path: 'coupon/:duplicate/:coupon_id', component: CouponFormComponent, data: { coupon_id: "", duplicate: "" }, canActivate: [AuthGuard] },
  {
    path: 'coupon/create', component: CouponFormComponent, children: [
      { path: "", component: InfluencerSectionComponent }
    ], canActivate: [AuthGuard]
  },
  { path: 'redeem', redirectTo: 'redeem/history', pathMatch: 'full' },
  { path: 'redeem/history', component: RedeemComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingComponent, canActivate: [AuthGuard] },
  { path: 'activity/log', component: ActivitylogComponent, canActivate: [AuthGuard] },
  { path: 'bulk/coupon/update', component: ImportCouponComponent, canActivate: [AuthGuard] },
  { path: 'config', component: ConfigComponent, canActivate: [AuthGuard] },
  { path: 'update/coupon/type', component: UpdateCouponTypeComponent, canActivate: [AuthGuard] },
  { path: 'bulk-coupon', component: BulkCouponComponent, canActivate: [AuthGuard] },
  { path: 'approvals', component: ApprovalsComponent, canActivate: [AuthGuard] },
  { path: 'approvals/approve/:coupon_id', component: ApproveFormComponent, data: { coupon_id: "" }, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'activity/userlog', component: ActivitylogUserComponent, canActivate: [AuthGuard] },
  { path: 'influencer', component: InfluencerComponent, canActivate: [AuthGuard] },
  { path: 'adminconfig', component: AdminConfigComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
